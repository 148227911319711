import Chef from "./chefAbi";
import {SwapPlatforms} from "@/config/swapPlatforms";

export const config = {
    name: 'PolyBeta',
    url: "https://polybeta.finance/pools",
    chef: {
        address: "0x9581EA83B4BCd5F2c5f1705382FBd80a11E57DcD",
        rewardTokenTicker: "BETA",
        abi: Chef,
        rewardTokenFunction: "beta",
        pendingRewardsFunction: "pendingBeta"
    },
    swap: SwapPlatforms.OneInch
}

export default config;
